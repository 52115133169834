import request from "../utils/axios"

/**
 * 新增地址
 * @param params
 * @returns {*}
 */
export const saveAddress = (params) => {
    return request({
        url: '/member/address/add',
        method: 'POST',
        data: params
    })
}
/**
 * 编辑地址
 * @param params
 * @param addressId
 * @returns {*}
 */
export const updateAddress = (params, addressId) => {
    return request({
        url: '/member/address/update/' + addressId,
        method: 'POST',
        data: params
    })
}

/**
 * 删除地址
 * @param addressId
 * @returns {*}
 */
export const deleteAddress = (addressId) => {
    return request({
        url: '/member/address/delete/' + addressId,
        method: 'POST',
        data: addressId
    })
}


/**
 * 地址列表
 * @returns {*}
 */
export const getMemberAddressList = () => {
    return request({
        url: '/member/address/list',
        method: 'GET',
    })
}

/**
 * 地址详情
 * @param addressId
 * @returns {*}
 */
export const getMemberAddressDetail = (addressId) => {
    return request({
        url: '/member/address/' + addressId,
        method: 'GET',
    })
}

/**
 * 完整省市区数据
 * @returns {AxiosPromise}
 */
export const getAreaAll = () => {
    return request({
        url: '/public/area/all',
        method: 'GET',
    })
}
