<template>
  <van-overlay :show="loading" z-index="9999" lock-scroll :class-name="className">
    <div class="wrap">
      <div class="block">
        <van-loading size="64px" type="spinner" color="#777" vertical>{{loadingTxt}}</van-loading>
      </div>
    </div>

  </van-overlay>

</template>

<script>
import Vue from "vue";
import {Loading, Overlay} from 'vant'
Vue.use(Loading)
Vue.use(Overlay)
export default {
  name: "LoadingOverlay",
  props:{
    className:{
      type: String,
      default: 'loading-overlay'
    },
    loadingTxt:{
      type: String,
      default: '加载中...'
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="sass">
.loading-overlay
  //background-color: rgba(255, 255, 255, 0.16)
  background-color: rgba(255, 255, 255, 0.68)
  //background-color: rgba(0, 0, 0, 0.16)
.wrap
  display: flex
  justify-content: center
  align-items: center
  height: 100vh
.block
  width: 120px
  height: 120px

</style>
