<template>
  <div class="address-edit-box">
    <van-address-edit
        :area-list="areaData"
        show-postal
        :show-delete="showDelete"
        show-set-default
        show-search-result
        :address-info="addressInfo"
        tel-maxlength="11"
        :area-columns-placeholder="['请选择', '请选择', '请选择']"
        @save="onSave"
        @delete="onDelete"
    />
    <wechat/>
    <loading-overlay :loading="loading"/>
  </div>
</template>

<script>
import Vue from 'vue';
import {AddressEdit, Toast} from 'vant';
import {saveAddress, getMemberAddressDetail, updateAddress, deleteAddress, getAreaAll} from '@/api/address'
import Wechat  from '@/components/Wechat'
import LoadingOverlay from "@/components/LoadingOverlay";
Vue.use(AddressEdit);
Vue.use(Toast);

export default {
  name: "addressEdit",
  components: {Wechat, LoadingOverlay},
  data() {
    return {
      loading: false,
      areaDataInit: false,
      areaData: {}, // 省市区数据
      province_list: {}, // 省
      city_list: {}, // 市
      county_list: {}, // 区
      showDelete: false,// 是否显示删除按钮
      editAddressId: '', // 要编辑的地址
      addressInfo: {//收货人信息初始值
        name: '',//姓名
        tel: '',//电话
        province: '',//省份
        city: '',//城市
        country: '',//区县
        areaCode: '',//地址code：ID
        addressDetail: '',//详细地址
        isDefault: false,//是否选择默认
      },
    };
  },
  methods: {
    onSave(content) {
      var params = {};
      params.name = content.name;
      params.phoneNumber = content.tel;
      params.gender = 0;
      params.regionCode = content.areaCode;
      params.cityCode = content.areaCode.substring(0, 4) + "00";
      params.provinceCode = content.areaCode.substring(0, 2) + "0000";
      params.detailAddress = content.addressDetail;
      params.postCode = content.postalCode;
      if (content.isDefault) {
        params.defaultStatus = 1;
      } else {
        params.defaultStatus = 0;
      }
      // 有id编辑地址
      if (this.editAddressId) {
        updateAddress(params, this.editAddressId).then(res => {
          if (res.code == 200) {
            Toast("地址编辑成功");
            this.$router.replace({path: '/addressList'});
          } else {
            Toast.fail(res.message || '地址编辑失败，请稍候再试');
          }
        }, err => {
          Toast.fail(err.message || '地址编辑失败，请稍候再试');
        })
      } else {
        saveAddress(params).then(res => {
          if (res.code == 200) {
            Toast("新增地址成功");
            this.$router.replace({path: '/addressList'});
          } else {
            Toast.fail(res.message || '新增地址成功，请稍候再试');
          }
        }, err => {
          Toast.fail(err.message || '新增地址成功，请稍候再试');
        })
      }
    },
    onDelete() {
      if (this.editAddressId) {
        deleteAddress(this.editAddressId).then(res => {
          if (res.code == 200) {
            Toast("删除地址成功");
            this.$router.replace({path: '/addressList'});
          }
        }, err => {
          Toast.fail(err.message || '删除地址失败，请稍候再试');
        })
      } else {
        this.$router.replace({path: '/addressList'});
      }
    },
    // 获取所有省市区数据
    async getArea() {
      this.loading = true
      return new Promise((resolve, reject) => {
        if(this.areaDataInit){
          this.loading = false
          return resolve(true)
        }
        getAreaAll().then(res => {
          this.loading = false
          if (res.code == 200) {
            var area = res.data;
            // 递归
            this.recursionArea(area);
            return resolve(true)
          }else{
            Toast.fail(res.message || '省市区数据获取失败');
            return reject(false)
          }
        }, err => {
          console.log(err)
          this.loading = false
          Toast.fail( '省市区数据获取出错');
          return reject(false)
        })
      })

    },
    fetchAddressInfo(){
      if (this.editAddressId) {
        this.loading = true
        getMemberAddressDetail(this.editAddressId).then(res => {
          this.loading = false
          if (res.code == 200) {
            var addressDetail = res.data;
            // 编辑回显数据
            this.addressInfo.name = addressDetail.name
            this.addressInfo.tel = addressDetail.phoneNumber
            this.addressInfo.province = addressDetail.provinceName
            this.addressInfo.city = addressDetail.cityName
            this.addressInfo.county = addressDetail.regionName
            this.addressInfo.areaCode = addressDetail.regionCode + '';
            this.addressInfo.postalCode = addressDetail.postCode
            this.addressInfo.addressDetail = addressDetail.detailAddress
            this.addressInfo.isDefault = addressDetail.defaultStatus ? true : false;
          } else {
            Toast.fail(res.message || '地址详情获取失败，请稍候再试');
          }
        }, err => {
          this.loading = false
          Toast.fail(err.message || '地址详情获取失败，请稍候再试');
        })
      }
    },

    recursionArea(areaData) {
      for (var i = 0; i < areaData.length; i++) {
        var provinceCode = areaData[i].id;
        this.province_list[provinceCode] = areaData[i].areaName;
        var provinceChildren = areaData[i].sub;
        if (provinceChildren) {
          for (var m = 0; m < provinceChildren.length; m++) {
            var cityCode = provinceChildren[m].id;
            this.city_list[cityCode] = provinceChildren[m].areaName;
            var cityChildren = provinceChildren[m].sub;
            if (cityChildren) {
              for (var n = 0; n < cityChildren.length; n++) {
                var countyCode = cityChildren[n].id;
                this.county_list[countyCode] = cityChildren[n].areaName;
              }
            }
          }
        }
      }
      this.areaData.province_list = this.province_list;
      this.areaData.city_list = this.city_list;
      this.areaData.county_list = this.county_list;
      this.areaData = JSON.parse(JSON.stringify(this.areaData))
    }
  },
  mounted() {
    // 获取所有省市区数据
    this.getArea().then(()=>{
      this.fetchAddressInfo()
    }, err=>{
      console.log(err)
    });

  },
  created() {

    // 接收地址id
    this.editAddressId = this.$route.query.addressId;
    if (this.editAddressId) {
      // 是否显示删除按钮
      this.showDelete = true;
    }
  }
}
</script>

<style scoped>
.address-edit-box {
  height: 100vh;
  background: #F7F8FA;
}
</style>
